import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { routerMiddleware } from 'connected-react-router';
// import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory as createHistory } from 'history';
import createRootReducer from './reducers';
// import cacheMiddleWare from './middlewares/cacheMiddleware';

const history = createHistory();

// Leave this blank. Initial state is loaded through reducers.
const initialState = {};

// const middleware = [thunk, routerMiddleware(history), cacheMiddleWare];
const middleware = [thunk, routerMiddleware(history)];
// Support for redux devtools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
	key: 'auth',
	storage: storageSession,
	whitelist: [
		'login',
		'users',
		'conditions',
		'tickets',
		'questionnaires',
		'prompt',
		'settings',
		'activities',
		'libraries',
		'statistics',
		'calendar',
		'meetings',
		'poa',
		'table',
		'journals',
    'surveys'
	],
	blacklist: ['router', 'generic', 'websockets', 'localize'],
	transforms: [
		createTransform(JSON.stringify, (toRehydrate) =>
			JSON.parse(toRehydrate, (key, value) =>
				typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/) ? new Date(value) : value
			)
		),
	],
};

const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

// Redux store
const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

// const replacer = (key, value) => value instanceof Date ? value.toISOString() : value

// const reviver = (key, value) =>
//   (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/))
//     ? new Date(value)
//     : value

// export const encode = toDeshydrate => JSON.stringify(toDeshydrate, replacer)

// export const decode = toRehydrate => JSON.parse(toRehydrate, reviver)

const persistor = persistStore(store);

export { history, store, persistor };
